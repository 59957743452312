<template>
  <div class="forms">
    <form id="personForm" @submit.prevent="generateURL" @reset.prevent="clearForm">
      <div class="form-group">
        <label class="sr-only">Z kim umowa?</label>
        <input id="contract-name" name="contract-name" type="text" class="form-control" placeholder="Z kim umowa?" v-model="contract.name">
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gender" id="gender-male" value="male" v-model="contract.gender">
        <label class="form-check-label" for="gender-male">Mężczyzna</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="gender" id="gender-female" value="female" v-model="contract.gender">
        <label class="form-check-label" for="gender-female">Kobieta</label>
      </div>

      <hr>
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-6">
            <div class="row">
              <div class="form-group">
                <label class="sr-only">Rozpoczęcie pracy</label>
                <input id="contract-start" name="contract-start" type="text" class="form-control" placeholder="Rozpoczęcie pracy" v-model="contract.start">
              </div>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="row">
              <div class="form-group">
                <label class="sr-only">Zakończenie pracy</label>
                <input id="contract-end" name="contract-end" type="text" class="form-control" placeholder="Zakończenie pracy" v-model="contract.end">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
          <button type="submit" class="btn btn-primary">Zapisz</button>
          <button type="reset" id="clearForm" class="btn btn-default">Wyczyść</button>

          <router-link to="/settings" class="btn btn-default settings"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg></router-link>
      </div>
    </form>
    <div class="form-group contract-link" v-if="newURL">
      <label class="sr-only">Link do&nbsp;umowy</label>
      <input class="form-control" type="text" v-model="newURL">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContractForm',
  props: ['contract'],
  data: () => ({
    newURL: '',
  }),
  methods: {
    generateURL() {
      const params = new URLSearchParams();
      Object.entries(this.contract).forEach(([key, value]) => {
        params.append(key, value);
      });
      this.newURL = `${window.location.href.split('?')[0]}?${params.toString()}`;
      navigator.clipboard.writeText(this.newURL);
    },
    clearForm() {
      this.$emit('clear');
      this.newURL = '';
    },
  },
};
</script>

<style scoped>
  .settings {
    margin-left: 4rem;
    padding: .78rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
</style>
