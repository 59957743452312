<template>
  <div class="wrap">
    <section class="contract">
      <div class="text-right">{{ companyObject.city }}, <span class="contract-end" contenteditable="true">{{ contract.end || '...........................'  }}</span></div>
      <h1>Oświadczenie o&nbsp;wyrażeniu zgody na&nbsp;publikację wizerunku</h1>

      <p>Ja, niżej {{ male ? 'podpisany' : 'podpisana'}} <span class="contract-id-number" contenteditable="true">{{ contract.name || '...........................' }}</span> wyrażam zgodę na&nbsp;nieograniczone czasowo udostępnienie mojego wizerunku, głosu a&nbsp;także artystycznego wykonania, które zostało zarejestrowane podczas realizacji dzieła. <span class="contract-terms" contenteditable="true">{{ contract.terms || '' }}</span></p>
      <p>Dotyczy to mojego wizerunku w&nbsp;postaci zdjęć i&nbsp;nagrań wideo, które zostały zrealizowane w&nbsp;celach artystycznych i&nbsp;komercyjnych przez Zleceniodawcę w&nbsp;dniach <span class="contract-start" contenteditable="true"> {{ contract.start || '...........................'  }}</span> - <span class="contract-end" contenteditable="true">{{ contract.end || '...........................'  }}</span></p>

      <div class="contract-bottom row">
        <div class="col-xs-6 text-center">
        </div>
        <div class="col-xs-6 text-center">
          <hr>
          Wykonawca
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import textMixins from '@/mixins/texts-mixin';

export default {
  name: 'AuthorText',
  mixins: [textMixins],
};
</script>
