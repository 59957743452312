<template>
  <div>
    <AuthorForm
      :contract="contract"
      @clear="clearForm"
    />
    <AuthorText
      :contract="contract"
      :company="company"
      :companyObject="companyObject"
    />
  </div>
</template>

<script>
import viewsMixin from '@/mixins/views-mixin';
import AuthorText from '@/components/Author.vue';
import AuthorForm from '@/components/AuthorForm.vue';

export default {
  name: 'Artist',
  components: {
    AuthorForm,
    AuthorText,
  },
  mixins: [viewsMixin],
};
</script>
